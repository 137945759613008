
import { setTimeout } from 'timers'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showModalStatus: false,
      modalStatus: {
        isSuccess: true,
        title: 'เข้าสู่ระบบสำเร็จ',
        subTitle: '',
        timer: 1500,
      },
    }
  },
  computed: {
    ...mapGetters(['showDialog', 'firstLogin']),
    onShowLoginSuccess() {
      return `${this.$store.getters.showDialog}`
    },
  },
  mounted() {
    if (this.$store.getters.firstLogin === true) {
      if (this.$store.getters.errorCode === 200) {
        this.showModalStatus.isSuccess = true
        this.showModalStatus.title = 'เข้าสู่ระบบสำเร็จ'
      }
      if (this.$store.getters.errorCode === 401) {
        this.showModalStatus.isSuccess = false
        this.showModalStatus.title = 'ไม่สำเร็จ'
        this.showModalStatus.subTitle = 'ไม่มีสิทธิ์เข้าสู่ระบบได้'
      }
      if (
        this.$store.getters.errorCode === 400 ||
        this.$store.getters.errorCode === 500
      ) {
        this.showModalStatus.isSuccess = false
        this.showModalStatus.title = 'ไม่สำเร็จ'
        this.showModalStatus.subTitle =
          'มีบางอย่างผิดพลาดกรุณาติดต่อผู้ดูแลระบบ'
      }
      this.showModalStatus = this.$store.getters.showDialog
      setTimeout(function () {
        this.$store.commit('SHOW_DIALOG', false)
        this.$store.commit('FIRST_LOGIN', false)
      }, 1500)
    }
  },
  watch: {
    onShowLoginSuccess() {
      this.showModalStatus = this.$store.getters.showDialog
    },
  },
}
