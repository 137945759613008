
export default {
  // name: 'Error403',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    nayooWebURL: process.env.NAYOO_WEB_URL,
  }),
}
