
import ModalStatus from '~/components/v1/shares/ModalStatus'

export default {
  name: 'DefaultLayout',
  components: {
    ModalStatus,
  },
  data() {
    return {
      dialogLogout: false,
      value: null,
      path: this.$route.fullPath,
      user: {},
      clipped: true,
      drawer: false,
      fixed: false,
      miniVariant: false,
      liftDrawer: false,
      title: 'Vuetify.js',
      noti: 0,
      mainMenu: {
        selectedMenuItem: 0,
        menuItems: [
          {
            name: 'myPosts',
            text: 'ประกาศของฉัน',
            icon: 'mdi-format-list-bulleted',
            uri: '/dashboard/posts',
          },
          {
            name: 'myRefers',
            text: 'รับงานจากน่าอยู่',
            icon: 'mdi-tag-outline',
            uri: '/dashboard/refers',
          },
          {
            name: 'mySearch',
            text: 'ค้นหาทรัพย์',
            icon: 'mdi-magnify',
            uri: '/dashboard/search',
          },
        ],
      },
    }
  },
  created() {
    // this.getUser()
    this.changValue()
  },
  mounted() {
    this.getUser()
  },
  methods: {
    getShieldColor(approved) {
      if (approved === 0) {
        return 'grey'
      } else if (approved === 1) {
        return 'green'
      } else if (approved === 3) {
        return 'blue'
      } else {
        return 'grey'
      }
    },
    async getUser() {
      await this.$axios.$get(`/api/posts/agentprofile`).then((response) => {
        if (response.data) {
          this.user = response.data
          this.noti = response.data.total_refer
        }
      })
      // console.log(this.user)
    },
    async logout() {
      await this.$auth.logout()
      this.$router.push('/auth/login')
    },
    changValue() {
      if (this.path === '/dashboard/posts') {
        this.value = 'myPosts'
      } else if (this.path === '/dashboard/refers') {
        this.value = 'myRefers'
      } else if (this.path === '/dashboard/posts/create') {
        this.value = 'createPost'
      }
    },
    async getNoti() {
      await this.$axios.$get(`/api/posts/agentprofile`).then((response) => {
        if (response.data) {
          this.noti = response.data.total_refer
        }
      })
    },
    showFooter() {
      // console.log(this.$nuxt.$route.name)
      if (this.$nuxt.$route.name === 'dashboard-posts-refer_id-review') {
        return true
      }
      if (this.$nuxt.$route.name === 'dashboard-refers-id-review') {
        return true
      }
      if (this.$nuxt.$route.name === 'dashboard-refers-id-fullreview') {
        return true
      }
      return false
    },
    checkDesktop() {
      console.log(this.$vuetify.breakpoint.mobile)
    },
    toMenuLink(uri, value) {
      this.$router.push(uri)
      this.value = value
    },
    toNayoo() {
      // function for redirect to nayoo
      const start = Math.random().toString(36).slice(2, 7)
      const end = Math.random().toString(36).slice(2, 7)
      let userId = ''
      if (this.user.firebase_id) {
        userId = this.user.firebase_id
      } else if (this.user.facebook_id) {
        userId = this.user.facebook_id
      } else if (this.user.line_token) {
        userId = this.user.line_token
      } else if (this.user.email) {
        userId = this.user.email
      } else if (this.user.login_phone) {
        userId = this.user.login_phone
      } else {
        return false
      }
      const userToken = start + btoa(userId) + end
      const win = window.open(
        `${process.env.NAYOO_WEB_URL}agenthome_callback?token=${userToken}`,
        '_blank'
      )
      // const win = window.open(`${config.AGENTHOME_URL}nayoo-callback?fid=${start + this.$store.state.user.firebase_id + end}`, '_blank')
      win.focus()
    },
  },
}
