// import axios from 'axios'
// import qs from 'qs'
export default (ctx, inject) => {
  inject('lineAuth', {
    request () {
      // console.log(process.env.APP_URL)
      location.href = 'https://access.line.me/oauth2/v2.1/authorize?' +
        'response_type=code&' +
        'scope=profile%20openid%20email&' +
        'client_id=' + process.env.LINE_CHANNEL_ID + '&' +
        // 'redirect_uri=' + process.env.API_URL + '/auth/line/callback' + '&' +
        'redirect_uri=' + encodeURIComponent(process.env.APP_URL + '/line_callback') + '&' +
        // 'state=' + encodeURIComponent(ctx.route.path) + '&' +
        'state=' + Math.random().toString(36).substring(8) + '&' +
        'nonce=' + Math.random().toString(36).substring(7)
    },
  })
}
