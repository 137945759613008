export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const DesktopImageGallery = () => import('../../components/desktop/ImageGallery.vue' /* webpackChunkName: "components/desktop-image-gallery" */).then(c => wrapFunctional(c.default || c))
export const ErrorsError401 = () => import('../../components/errors/error401.vue' /* webpackChunkName: "components/errors-error401" */).then(c => wrapFunctional(c.default || c))
export const ErrorsError403 = () => import('../../components/errors/error403.vue' /* webpackChunkName: "components/errors-error403" */).then(c => wrapFunctional(c.default || c))
export const ErrorsError404 = () => import('../../components/errors/error404.vue' /* webpackChunkName: "components/errors-error404" */).then(c => wrapFunctional(c.default || c))
export const ErrorsError500 = () => import('../../components/errors/error500.vue' /* webpackChunkName: "components/errors-error500" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchDesktop = () => import('../../components/filter-search/desktop.vue' /* webpackChunkName: "components/filter-search-desktop" */).then(c => wrapFunctional(c.default || c))
export const FilterMypostsMobile = () => import('../../components/filter-search/filter-myposts-mobile.vue' /* webpackChunkName: "components/filter-myposts-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterMyposts = () => import('../../components/filter-search/filter-myposts.vue' /* webpackChunkName: "components/filter-myposts" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchMobile = () => import('../../components/filter-search/mobile.vue' /* webpackChunkName: "components/filter-search-mobile" */).then(c => wrapFunctional(c.default || c))
export const PostCalculateCommission = () => import('../../components/post/CalculateCommission.vue' /* webpackChunkName: "components/post-calculate-commission" */).then(c => wrapFunctional(c.default || c))
export const PostCardProduct = () => import('../../components/post/CardProduct.vue' /* webpackChunkName: "components/post-card-product" */).then(c => wrapFunctional(c.default || c))
export const PostItem = () => import('../../components/post/PostItem.vue' /* webpackChunkName: "components/post-item" */).then(c => wrapFunctional(c.default || c))
export const PostUploadImage = () => import('../../components/post/UploadImage.vue' /* webpackChunkName: "components/post-upload-image" */).then(c => wrapFunctional(c.default || c))
export const ReferCard = () => import('../../components/refer/ReferCard.vue' /* webpackChunkName: "components/refer-card" */).then(c => wrapFunctional(c.default || c))
export const ReferFilter = () => import('../../components/refer/ReferFilter.vue' /* webpackChunkName: "components/refer-filter" */).then(c => wrapFunctional(c.default || c))
export const ShareButton = () => import('../../components/share/ShareButton.vue' /* webpackChunkName: "components/share-button" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchComponentsButtonSelectBorder = () => import('../../components/filter-search/components/button-select-border.vue' /* webpackChunkName: "components/filter-search-components-button-select-border" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchComponentsButtonSelect = () => import('../../components/filter-search/components/button-select.vue' /* webpackChunkName: "components/filter-search-components-button-select" */).then(c => wrapFunctional(c.default || c))
export const FilterProvince = () => import('../../components/filter-search/components/filter-province.vue' /* webpackChunkName: "components/filter-province" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchComponentsFullFilter = () => import('../../components/filter-search/components/full-filter.vue' /* webpackChunkName: "components/filter-search-components-full-filter" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchComponentsPriceSelect = () => import('../../components/filter-search/components/price-select.vue' /* webpackChunkName: "components/filter-search-components-price-select" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchComponentsSaleRentSelect = () => import('../../components/filter-search/components/sale-rent-select.vue' /* webpackChunkName: "components/filter-search-components-sale-rent-select" */).then(c => wrapFunctional(c.default || c))
export const FilterSearch = () => import('../../components/filter-search/components/search.vue' /* webpackChunkName: "components/filter-search" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchComponentsSortFilter = () => import('../../components/filter-search/components/sort-filter.vue' /* webpackChunkName: "components/filter-search-components-sort-filter" */).then(c => wrapFunctional(c.default || c))
export const FilterSearchComponentsTypeSelect = () => import('../../components/filter-search/components/type-select.vue' /* webpackChunkName: "components/filter-search-components-type-select" */).then(c => wrapFunctional(c.default || c))
export const V1SharesLogin = () => import('../../components/v1/shares/Login.vue' /* webpackChunkName: "components/v1-shares-login" */).then(c => wrapFunctional(c.default || c))
export const V1SharesModalStatus = () => import('../../components/v1/shares/ModalStatus.vue' /* webpackChunkName: "components/v1-shares-modal-status" */).then(c => wrapFunctional(c.default || c))
export const V1SharesPasswordInput = () => import('../../components/v1/shares/PasswordInput.vue' /* webpackChunkName: "components/v1-shares-password-input" */).then(c => wrapFunctional(c.default || c))
export const V1SharesRegister = () => import('../../components/v1/shares/Register.vue' /* webpackChunkName: "components/v1-shares-register" */).then(c => wrapFunctional(c.default || c))
export const V1SharesTextInput = () => import('../../components/v1/shares/TextInput.vue' /* webpackChunkName: "components/v1-shares-text-input" */).then(c => wrapFunctional(c.default || c))
export const V1SharesYoutubeItem = () => import('../../components/v1/shares/YoutubeItem.vue' /* webpackChunkName: "components/v1-shares-youtube-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
