export const state = () => {
  return {
    showDialog: false,
    firstLogin: false,
    errorCode: 0
  }
}

export const mutations = {
  SHOW_DIALOG(state, value) {
    if (state.firstLogin) {
      state.showDialog = value
    }
  },
  FIRST_LOGIN(state, value) {
    state.firstLogin = value
  },
  SET_ERROR_CODE(state, value) {
    state.errorCode = value
  },
}

export const getters = {
  showDialog(state) {
    return state.showDialog
  },
  firstLogin(state) {
    return state.firstLogin
  },
  errorCode(state) {
    return state.errorCode
  },
}
