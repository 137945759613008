import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _05d9875c = () => interopDefault(import('../pages/facebook_callback.vue' /* webpackChunkName: "pages/facebook_callback" */))
const _588c0e3e = () => interopDefault(import('../pages/health_check.vue' /* webpackChunkName: "pages/health_check" */))
const _374aac6c = () => interopDefault(import('../pages/how-to-register.vue' /* webpackChunkName: "pages/how-to-register" */))
const _7de46fe4 = () => interopDefault(import('../pages/line_callback.vue' /* webpackChunkName: "pages/line_callback" */))
const _a719d8b0 = () => interopDefault(import('../pages/nayoo-callback.vue' /* webpackChunkName: "pages/nayoo-callback" */))
const _4c272b84 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6e571acf = () => interopDefault(import('../pages/dashboard/posts/index.vue' /* webpackChunkName: "pages/dashboard/posts/index" */))
const _2facc7cd = () => interopDefault(import('../pages/dashboard/refers/index.vue' /* webpackChunkName: "pages/dashboard/refers/index" */))
const _2956a97c = () => interopDefault(import('../pages/dashboard/search/index.vue' /* webpackChunkName: "pages/dashboard/search/index" */))
const _1f5c3c93 = () => interopDefault(import('../pages/ui-template/yt.vue' /* webpackChunkName: "pages/ui-template/yt" */))
const _f77eec62 = () => interopDefault(import('../pages/dashboard/posts/create.vue' /* webpackChunkName: "pages/dashboard/posts/create" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ae977938 = () => interopDefault(import('../pages/dashboard/posts/_post_id/review-step4.vue' /* webpackChunkName: "pages/dashboard/posts/_post_id/review-step4" */))
const _550e0bba = () => interopDefault(import('../pages/dashboard/posts/_post_id/update.vue' /* webpackChunkName: "pages/dashboard/posts/_post_id/update" */))
const _18a1a2e6 = () => interopDefault(import('../pages/dashboard/posts/_refer_id/contract-form.vue' /* webpackChunkName: "pages/dashboard/posts/_refer_id/contract-form" */))
const _60f38576 = () => interopDefault(import('../pages/dashboard/posts/_refer_id/create.vue' /* webpackChunkName: "pages/dashboard/posts/_refer_id/create" */))
const _5f0297d8 = () => interopDefault(import('../pages/dashboard/posts/_refer_id/create-post.vue' /* webpackChunkName: "pages/dashboard/posts/_refer_id/create-post" */))
const _0ce505a1 = () => interopDefault(import('../pages/dashboard/posts/_refer_id/review.vue' /* webpackChunkName: "pages/dashboard/posts/_refer_id/review" */))
const _1bfa98b1 = () => interopDefault(import('../pages/dashboard/refers/_id/fullreview.vue' /* webpackChunkName: "pages/dashboard/refers/_id/fullreview" */))
const _776665e2 = () => interopDefault(import('../pages/dashboard/refers/_id/review.vue' /* webpackChunkName: "pages/dashboard/refers/_id/review" */))
const _9c3af996 = () => interopDefault(import('../pages/dashboard/posts/_refer_id/_id/edit-post.vue' /* webpackChunkName: "pages/dashboard/posts/_refer_id/_id/edit-post" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/facebook_callback",
    component: _05d9875c,
    name: "facebook_callback"
  }, {
    path: "/health_check",
    component: _588c0e3e,
    name: "health_check"
  }, {
    path: "/how-to-register",
    component: _374aac6c,
    name: "how-to-register"
  }, {
    path: "/line_callback",
    component: _7de46fe4,
    name: "line_callback"
  }, {
    path: "/nayoo-callback",
    component: _a719d8b0,
    name: "nayoo-callback"
  }, {
    path: "/auth/login",
    component: _4c272b84,
    name: "auth-login"
  }, {
    path: "/dashboard/posts",
    component: _6e571acf,
    name: "dashboard-posts"
  }, {
    path: "/dashboard/refers",
    component: _2facc7cd,
    name: "dashboard-refers"
  }, {
    path: "/dashboard/search",
    component: _2956a97c,
    name: "dashboard-search"
  }, {
    path: "/ui-template/yt",
    component: _1f5c3c93,
    name: "ui-template-yt"
  }, {
    path: "/dashboard/posts/create",
    component: _f77eec62,
    name: "dashboard-posts-create"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }, {
    path: "/dashboard/posts/:post_id/review-step4",
    component: _ae977938,
    name: "dashboard-posts-post_id-review-step4"
  }, {
    path: "/dashboard/posts/:post_id/update",
    component: _550e0bba,
    name: "dashboard-posts-post_id-update"
  }, {
    path: "/dashboard/posts/:refer_id/contract-form",
    component: _18a1a2e6,
    name: "dashboard-posts-refer_id-contract-form"
  }, {
    path: "/dashboard/posts/:refer_id/create",
    component: _60f38576,
    name: "dashboard-posts-refer_id-create"
  }, {
    path: "/dashboard/posts/:refer_id/create-post",
    component: _5f0297d8,
    name: "dashboard-posts-refer_id-create-post"
  }, {
    path: "/dashboard/posts/:refer_id/review",
    component: _0ce505a1,
    name: "dashboard-posts-refer_id-review"
  }, {
    path: "/dashboard/refers/:id/fullreview",
    component: _1bfa98b1,
    name: "dashboard-refers-id-fullreview"
  }, {
    path: "/dashboard/refers/:id/review",
    component: _776665e2,
    name: "dashboard-refers-id-review"
  }, {
    path: "/dashboard/posts/:refer_id/:id?/edit-post",
    component: _9c3af996,
    name: "dashboard-posts-refer_id-id-edit-post"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
