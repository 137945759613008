
import error401 from '~/components/errors/error401.vue'
import error403 from '~/components/errors/error403.vue'
import error404 from '~/components/errors/error404.vue'
import error500 from '~/components/errors/error500.vue'
export default {
  name: 'NuxtError',
  components: {
    error403,
    error404,
    error500,
    error401,
  },
  // layout: 'blog',
  layout(context) {
    if (context.error.statusCode === 500 || context.error.statusCode === 401) {
      return 'empty'
    } else {
      return 'default'
    }
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 403) {
        return error403
      }
      if (this.error.statusCode === 404) {
        return error404
      }
      // catch everything else
      return error500
    },
  },
}
