import inside from '@turf/inside'
import moment from 'moment'
import qs from 'qs'
export default (ctx, inject) => {
  inject('helpers', {
    inSevenDays(val) {
      return moment(val).add(6, 'days').format('DD/MM/YY')
    },
    phoneFormat(phone) {
      return phone.replace(/(\d{3})(\d{7})/, '$1-$2')
    },
    youtubeId(youtubeURL) {
      if (youtubeURL.includes('https://') || youtubeURL.includes('http://')) {
        const tmp = youtubeURL.split('?')
        if (tmp.length > 1) {
          const queries = qs.parse(tmp[1])
          if ('v' in queries) {
            return queries.v
          } else if ('feature' in queries || 't' in queries) {
            return tmp[0].split('/').slice(-1).pop()
          }
        } else if (youtubeURL.split('/').length > 1) {
          return youtubeURL.split('/').slice(-1).pop()
        }

        return ''
      }

      return youtubeURL
      // const url = youtubeURL.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/)/)
      // const Youtube_Id =
      //   undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
      // if (Youtube_Id === url[0]) {
      //   return ''
      // } else {
      //   return Youtube_Id
      // }
    },
    validateLatLng(latitude, longitude) {
      const lat = latitude
      const lng = longitude
      if (isFinite(lat) && (Math.abs(lat) < -90 || Math.abs(lat) > 90)) {
        this.errorModal('ละติจูด ตัวเลขจะอยุ่ระหว่าง -90 ถึง 90 ')
        return false
      } else if (
        isFinite(lng) &&
        (Math.abs(lng) < -180 || Math.abs(lng) > 180)
      ) {
        this.errorModal('ลองจิจูด ตัวเลขจะอยุ่ระหว่าง -180 ถึง 180 ')
        return false
      } else if (!lat || !lng) {
        this.errorModal('กรุณากรอกข้อมูลให้ครอบทั้ง ละติจูด - ลองจิจูด')
        return false
      }
      return true
    },
    async verifyCoordinateInSubDistrict(
      latitude,
      longitude,
      polygonPath,
      districtName,
      success,
      error,
      caught
    ) {
      if (this.validateLatLng(latitude, longitude)) {
        if (polygonPath?.data) {
          let checkInSideDistrict = 0
          await polygonPath.data.forEach((poly) => {
            if (districtName === poly.data.district.title.title_th) {
              const latlng = [longitude, latitude]
              const isInside = inside(latlng, poly.geometry)
              if (isInside) {
                checkInSideDistrict += 1
                success()
                // console.log('The point is within the polygon.')
              }
            }
          })
          if (checkInSideDistrict === 0) {
            error()
          }
        }
        // console.log(polyTrue, subDistrictName)
      }
    },
    getCenterFromPolygonData(polygonArr) {
      const latlngPath = []
      let sumLat = 0
      let sumLng = 0
      let cnt = 0
      for (const k2 in polygonArr) {
        latlngPath.push({ lat: polygonArr[k2][1], lng: polygonArr[k2][0] })
        sumLat += polygonArr[k2][1]
        sumLng += polygonArr[k2][0]
        cnt++
      }

      const center = {
        lat: sumLat / cnt,
        lng: sumLng / cnt,
      }
      return { center, latlngPath }
    },
  })
}
